class Location {
  City: string = "";
  State: string = "";
  Country: string = "";
}

class PictureModel {
  constructor(
    name: string,
    description: string,
    peopleInPhoto: string[],
    date: Date,
    city: string,
    state: string,
    country: string
  ) {
    this.name = name;
    this.description = description;
    this.peopleInPhoto = peopleInPhoto;
    this.date = date;
    this.location.City = city;
    this.location.State = state;
    this.location.Country = country;
  }

  name: string = "";
  description: string = "";
  peopleInPhoto: string[] = [];
  date: Date;
  location: Location = new Location();
}

export default PictureModel;
