import React from "react";
import PictureCard from "./PictureCard/PictureCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPresentation, faGrid } from "@fortawesome/pro-solid-svg-icons";
import classes from "./Pictures.module.scss";
import DefinedPictures from "./DefinedPictures/DefinedPictures";
import PictureModel from "./DefinedPictures/PictureModel";
import ReactImageGallery, {
  ReactImageGalleryItem,
  ReactImageGalleryProps,
} from "react-image-gallery";
import { Button } from "@mui/material";

const Pictures: React.FC = () => {
  const pictureCards = DefinedPictures.pictures.map((picture: PictureModel) => (
    <Grid item xs={12} sm={3} md={3} key={picture.name}>
      <PictureCard picture={picture} />
    </Grid>
  ));

  const createReactImageGallery = (): ReactImageGalleryItem[] => {
    let TempReactImageGalleryItemArray: ReactImageGalleryItem[] = [];

    DefinedPictures.pictures.map((picture: PictureModel) => {
      let TempReactImageGalleryItem = {
        original: picture.name,
        originalTitle: picture.description,
        bulletClass: "my-bullet-class-name",
      };

      TempReactImageGalleryItemArray.push(TempReactImageGalleryItem);
    });

    return TempReactImageGalleryItemArray;
  };

  const props: ReactImageGalleryProps = {
    items: createReactImageGallery(),
    autoPlay: false,
    showFullscreenButton: true,
    disableKeyDown: false,
  };

  const [open, setOpen] = React.useState(false);

  const buttonToggle = () => {
    setOpen(!open);
    console.log(open);
  };

  const presentationButton = open ? (
    <Button
      title={"Grid View"}
      className={classes.buttonPanelButton}
      onClick={buttonToggle}
    >
      <FontAwesomeIcon
        className={classes.buttonPanelButtonIcon}
        icon={faGrid}
      />
    </Button>
  ) : (
    <Button
      title={"Presentation View"}
      className={classes.buttonPanelButton}
      onClick={buttonToggle}
    >
      <FontAwesomeIcon
        className={classes.buttonPanelButtonIcon}
        icon={faPresentation}
      />
    </Button>
  );

  return (
    <div className={classes.content}>
      {open ? (
        <ReactImageGallery {...props} />
      ) : (
        <div className={classes.cardList}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 9, md: 12 }}
              className={classes.gridItem}
            >
              {pictureCards}
            </Grid>
          </Box>
        </div>
      )}
      <div className={classes.buttonPanel}>
        <div className={classes.buttonPanelButtons}>
          {presentationButton}
          {/*<Button className={classes.buttonPanelButton}>*/}
          {/*  <FontAwesomeIcon icon={faDownload} />*/}
          {/*</Button>*/}
        </div>
      </div>
    </div>
  );
};

export default Pictures;
