import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Timeline from "../Timeline/Timeline";
import Pictures from "../Pictures/Pictures";
import Eulogy from "../Eulogy/Eulogy";
import NavBar from "../../components/NavBar/NavBar";

import classes from "./Main.module.scss";

const Main: React.FC = () => {
  return (
    <Router>
      <NavBar />
      <div className={classes.content}>
        <Switch>
          <Route exact path={"/"}>
            <Timeline />
          </Route>
          <Route exact path={"/pictures"}>
            <Pictures />
          </Route>
          <Route exact path={"/eulogy"}>
            <Eulogy />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default Main;
