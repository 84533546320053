import React, { useEffect } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import gsap, { Linear } from "gsap";

import classes from "./Eulogy.module.scss";

import adult2021 from "../../assets/img/photos/adult-2021.jpg";

function Eulogy() {
  useEffect(() => {
    const timeline = gsap.timeline();

    timeline.add([
      gsap.fromTo(
        "#img",
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 1.5, ease: Linear.easeIn }
      ),
      gsap.fromTo(
        "#text",
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 1.5, delay: 0.75, ease: Linear.easeIn }
      ),
    ]);
  }, []);

  return (
    <Grid container className={classes.content}>
      <Grid item xs={12} md={12} lg={4} className={classes.gridItem}>
        <Card id={"img"} className={classes.card}>
          <CardContent className={classes.text}>
            <Card>
              <CardContent>
                <img
                  alt={"dorothy fay"}
                  src={adult2021}
                  className={classes.img}
                />
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={8} className={classes.gridItem}>
        <Card id={"text"} className={classes.card}>
          <CardContent className={classes.text}>
            <Card>
              <CardContent>
                <p>
                  Dorothy Fay, age 101, of Woodstock, IL passed away on Friday,
                  August 6, 2021 at home surrounded by her loving family. She
                  was born on July 28, 1920 to Fred William Bremer and Ethel M.
                  Beardsley Bremer Louis in Manistique, MI. Dorothy met and
                  married the love of her life Kenneth Fay on June 1, 1940 in
                  Dubuque, Iowa.
                </p>
                <p>
                  Dorothy has been a resident of Woodstock for over 80 years.
                  She loved to play bingo so much that she traveled around the
                  county to be able to play seven days a week. It was more than
                  just playing bingo to her, it was a chance to spend time with
                  her friends and catch up on the latest details of their lives.
                  Dorothy was also known to many as Grandma Dorothy as she
                  babysat many children in the community throughout the years.
                  She was very independent and drove well into her 90s and
                  continued to live in her own home. She had lots of hobbies and
                  interests like knitting, watching game shows, reading, playing
                  yahtzee and cheering for her beloved Cubs. Her greatest love
                  however was being with friends and family that she adored and
                  making memories to cherish for a lifetime.
                </p>
                <p>
                  She is survived by her son: Larry Fay (Linda); 4
                  Grandchildren: Connie (Jeff) Oberhofer, Kenneth (Robin)
                  Huffar, Robert (Bridget) Way, and Laurie (Brian) Smith; 8
                  Great Grandchildren: Jake (Ivy) Koldeway, Jared Oberhofer,
                  Sidney Smith, Tristan Smith, Brendan Smith, Danner Way, Kaylee
                  Smith, and Samantha Way; 3 Great-Great Grandchildren: Justin
                  Oberhofer, Quinn Koldeway, and Quade Koldeway. She also has
                  several nieces and nephews.
                </p>
                <p>
                  Dorothy is preceded by her husband and best friend of 38 years
                  Kenneth Fay; Daughter: Darleen Way; Great Grandson: Justin
                  Koldeway; and her siblings Ralph Bremer, Edith Bremer Thomas,
                  Gene Bremer, Grace Bremer Beardsley, Betty Bremer Andrews and
                  Beverly Bremer (infant).
                </p>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Eulogy;
