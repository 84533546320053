import PictureModel from "./PictureModel";
import baby1920 from "../../../assets/img/photos/baby-1920.bmp";
import toddler1923 from "../../../assets/img/photos/toddler-1923.bmp";
import adult2021 from "../../../assets/img/photos/adult-2021.jpg";
import GeneEdithRalph1925 from "../../../assets/img/photos/Gene, Edith and Ralph 1925.jpg";
import EthelWithFredBremerAll6KidsAndUnknownPerson from "../../../assets/img/photos/Ethel with Fred Bremer all 6 kids and unknown person.jpg";
import EthelWithAll6KidsHoldingBetty from "../../../assets/img/photos/Ethel with all 6 kids holding Betty.jpg";
import EthelBremer from "../../../assets/img/photos/Ethel Bremer.jpg";
import DorothBettyGraceAndCliff from "../../../assets/img/photos/Dorothy-Betty-Grace and Cliff.jpg";
import DorothyAndGrace from "../../../assets/img/photos/Dorothy and Grace.jpg";

class DefinedPictures {
  static pictures: PictureModel[] = [];

  static GeneratePictureDefinitions() {
    var tempPicture: PictureModel;

    tempPicture = new PictureModel(
      baby1920,
      "Dorthy, Darleane, Ethel, and Charlotte",
      ["Dorthy", "Darlene", "Ethel", "Charlotte"],
      new Date(1920, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      toddler1923,
      "Dorthy and Grace",
      ["Dorthy", "Grace"],
      new Date(1923, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      adult2021,
      "Dorthy ",
      ["Dorthy"],
      new Date(2017, 12, 23),
      "Wonder Lake",
      "Illinois",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      GeneEdithRalph1925,
      "Gene, Edith and Ralph ",
      ["Gene", "Edith", "Ralph"],
      new Date(1925, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      EthelWithFredBremerAll6KidsAndUnknownPerson,
      "Ethel with Fred Bremer all 6 kids and unknown person",
      ["Ethel", "Fred", "Edith", "Ralph", "Eugene", "Dorothy", "Grace", "Elizabeth",],
      new Date(1925, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      EthelWithAll6KidsHoldingBetty,
      "Ethel with all 6 kids holding betty",
      ["Ethel", "Edith", "Ralph", "Eugene", "Dorothy", "Grace", "Elizabeth",],
      new Date(1925, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      EthelBremer,
      "Ethel Bremer",
      ["Ethel Bremer"],
      new Date(1925, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      DorothBettyGraceAndCliff,
      "Dorothy Betty Grace and Cliff",
      ["Dorothy", "Elizabeth", "Grace", "Cliff"],
      new Date(1925, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);

    tempPicture = new PictureModel(
      DorothyAndGrace,
      "Dorothy and Grace",
      ["Dorothy", "Grace"],
      new Date(1925, 1, 1),
      "Manistique",
      "Michigan",
      "USA"
    );
    DefinedPictures.pictures.push(tempPicture);
  }
}

export default DefinedPictures;
