import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import classes from "./Timeline.module.scss";
import { faAngel, faBaby } from "@fortawesome/pro-solid-svg-icons";

import babyPhoto from "../../assets/img/photos/baby-1920.bmp";
import adult2021 from "../../assets/img/photos/adult-2021.jpg";

import { Card, CardContent, Grid } from "@mui/material";

const Timeline = () => {
  //return <div className={classes.content}></div>;
  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <h1>The Life Journey</h1>
        <h2 style={{ margin: 0 }}>~of~</h2>
        <h1>Dorothy Fay</h1>
      </div>
      <div>
        <VerticalTimeline layout={"2-columns"}>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            contentStyle={{
              background: "rgb(229, 152, 155)",
              color: "#343330",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(181, 131, 141)" }}
            date={"July 28th, 1920"}
            iconStyle={{ background: "rgb(229, 152, 155)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBaby} />}
          >
            <Card className={classes.timelineContentCard}>
              <CardContent>
                <h3 className="vertical-timeline-element-title">Sunrise</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Manistique, Michigan
                </h4>
                <Grid container>
                  <Grid item xs={12} className={classes.timelineGridItem}>
                    <img
                      alt={"Baby Dorothy Fay 1920"}
                      src={babyPhoto}
                      className={classes.photo}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.timelineGridItem}>
                    <p>
                      Baby Dorothy with her mother Ethel, grandmother, and great
                      grandmother.
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            contentStyle={{
              background: "rgb(229, 152, 155)",
              color: "#343330",
            }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(181, 131, 141)" }}
            date={"August 6th, 2021"}
            iconStyle={{ background: "rgb(229, 152, 155)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faAngel} />}
          >
            <Card className={classes.timelineContentCard}>
              <CardContent>
                <h3 className="vertical-timeline-element-title">Sunset</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Woodstock, Illinois
                </h4>
                <Grid container>
                  <Grid item xs={12} className={classes.timelineGridItem}>
                    <img
                      alt={"Dorothy Fay 2021"}
                      src={adult2021}
                      className={classes.photo}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.timelineGridItem}>
                    <p>
                      Dorothy Fay, living life to the fullest for 101 years.
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Timeline;
