import React from "react";
import { Dialog } from "@mui/material";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ onClose, open, children }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {children}
    </Dialog>
  );
};

export default Modal;
