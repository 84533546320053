import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import PictureModel from "../DefinedPictures/PictureModel";

import classes from "./PictureCard.module.scss";
import { Typography } from "@mui/material";
import Modal from "../../../components/Dialog/Dialog";

interface pictureCardProps {
  picture: PictureModel;
}

const PictureCard: React.FC<pictureCardProps> = (props: pictureCardProps) => {
  const [open, setOpen] = React.useState(false);
  const [gridCardDisplay, setGridCardDisplay] = useState("none");
  const [modalCardDisplay, setModalCardDisplay] = useState("none");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const gridCard = () => (
    <Card
      sx={{ maxWidth: 400 }}
      className={classes.card}
      onMouseEnter={() => {
        setGridCardDisplay("block");
      }}
      onMouseLeave={() => {
        setGridCardDisplay("none");
      }}
      onClick={() => {
        handleOpen();
      }}
    >
      <CardActionArea className={classes.cardActionArea}>
        <CardMedia
          component="img"
          height={400}
          image={props.picture.name}
          alt={props.picture.description}
          className={classes.cardMedia}
        />
      </CardActionArea>
      <CardContent
        className={classes.cardContent}
        style={{ display: gridCardDisplay }}
      >
        <div style={{ padding: "1rem" }}>
          <Typography gutterBottom variant="h5" component="div">
            {props.picture.description}
          </Typography>
          <Typography variant="body2">
            {props.picture.date.getFullYear()}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );

  const modalCard = () => (
    <Card
      className={classes.card}
      onMouseEnter={() => {
        setModalCardDisplay("block");
      }}
      onMouseLeave={() => {
        setModalCardDisplay("none");
      }}
    >
      <CardActionArea className={classes.cardActionArea}>
        <CardMedia
          component="img"
          image={props.picture.name}
          alt={props.picture.description}
          className={classes.cardMedia}
        />
      </CardActionArea>
      <CardContent
        className={classes.cardContent}
        style={{ display: modalCardDisplay }}
      >
        <div style={{ padding: "1rem" }}>
          <Typography gutterBottom variant="h5" component="div">
            {props.picture.description}
          </Typography>
          <Typography variant="body2">
            {props.picture.date.getFullYear()}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <div>
      {gridCard()}
      <Modal open={open} onClose={handleClose}>
        {/*<Card className={classes.modalCard}>*/}
        {/*  <img*/}
        {/*    alt={props.picture.description}*/}
        {/*    src={props.picture.name}*/}
        {/*    className={classes.modalImage}*/}
        {/*  />*/}
        {/*  <Typography variant="body2">{props.picture.description}</Typography>*/}
        {/*</Card>*/}
        {modalCard()}
      </Modal>
    </div>
  );
};

export default PictureCard;
