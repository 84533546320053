import React, { useEffect, useState } from "react";
import Loading from "./views/Loading/Loading";
import Main from "./views/Main/Main";

import classes from "./App.module.scss";
import DefinedPictures from "./views/Pictures/DefinedPictures/DefinedPictures";

function App() {
  const [loading, setLoading] = useState(true);
  const pictures = DefinedPictures.pictures;

  useEffect(() => {
    const loadPictures = () => {
      DefinedPictures.GeneratePictureDefinitions();
      setLoading(false);
    };
    loadPictures();
  }, [pictures]);

  return <div className={classes.App}>{loading ? <Loading /> : <Main />}</div>;
}

export default App;
