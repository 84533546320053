import React from "react";

import classes from "./Loading.module.scss";
import { CircularProgress } from "@mui/material";

function Loading() {
  return (
    <div className={classes.content}>
      <CircularProgress className={classes.loader} />
    </div>
  );
}

export default Loading;
