import React from "react";

import classes from "./NavBar.module.scss";
import { useWindowSize } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-solid-svg-icons";

import logo from "../../assets/img/logo.png";

function NavBar() {
  const history = useHistory();
  const [width] = useWindowSize();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleRouteTo = (route) => {
    history.push(route);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderNavItems = () => {
    let navItems;
    if (width <= 958) {
      navItems = (
        <div>
          <Button
            id="nav-button"
            aria-controls="nav-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <FontAwesomeIcon className={classes.menuIcon} icon={faBars} />
          </Button>
          <Menu
            id="nav-menu"
            MenuListProps={{
              "aria-labelledby": "nav-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleRouteTo("/")}>Timeline</MenuItem>
            <MenuItem onClick={() => handleRouteTo("/eulogy")}>Eulogy</MenuItem>
            <MenuItem onClick={() => handleRouteTo("/pictures")}>
              Pictures
            </MenuItem>
          </Menu>
        </div>
      );
    } else {
      navItems = (
        <div className={classes.navItemsList}>
          <MenuItem onClick={() => handleRouteTo("/")}>Timeline</MenuItem>
          <MenuItem onClick={() => handleRouteTo("/eulogy")}>Eulogy</MenuItem>
          <MenuItem onClick={() => handleRouteTo("/pictures")}>
            Pictures
          </MenuItem>
        </div>
      );
    }
    return navItems;
  };
  return (
    <div className={classes.navbar}>
      <img className={classes.logo} alt={"memorial logo"} src={logo} />
      {renderNavItems()}
    </div>
  );
}

export default NavBar;
